




import { Component, Vue } from 'vue-property-decorator';
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc';
import { oidcSettings } from '@/config/oidc'

@Component({
    components: {}
})
export default class SilentCallback extends Vue {
    constructor() {
        super();
    }

    mounted(){
        vuexOidcProcessSilentSignInCallback(oidcSettings);
    }
}
